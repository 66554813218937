<template>
  <div class="container mx-auto" ref="orderFormRef">
    <RpmDashboardErrors v-bind:errors="errors" v-bind:title="'Order Form Errors'"/>
    <h1 class="capitalize mb-4 text-3xl">{{ clientName}} Patient Order Form</h1>
    <div class="grid grid-cols-2 gap-4">
      <div class="mb-4">
        <label class="block text-gray-700 text-sm font-bold mb-2" for="first_name">
          First name <span class="required">*</span>
        </label>
        <input
            class="shadow appearance-none border rounded w-full py-2 px-3 input__registerPatient text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            id="first_name"
            type="text"
            placeholder=""
            v-model="patient.first_name"
        />
      </div>
      <div class="mb-4">
        <label class="block text-gray-700 text-sm font-bold mb-2" for="middle_name">
          Middle name
        </label>
        <input
            class="shadow appearance-none border rounded w-full py-2 px-3 input__registerPatient text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            id="middle_name"
            type="text"
            placeholder=""
            v-model="patient.middle_name"
        />
      </div>
      <div class="mb-4">
        <label class="block text-gray-700 text-sm font-bold mb-2" for="last_name">
          Last name
        </label>
        <input
            class="shadow appearance-none border rounded w-full py-2 px-3 input__registerPatient text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            id="last_name"
            type="text"
            placeholder=""
            v-model="patient.last_name"
        />
      </div>
      <div class="mb-4">
        <label class="block text-gray-700 text-sm font-bold mb-2" for="email">
          Email address
        </label>
        <input
            class="shadow appearance-none border rounded w-full py-2 px-3 input__registerPatient text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            id="email"
            type="text"
            placeholder=""
            v-model="patient.email"
        />
      </div>
      <div class="mb-4">
        <label class="block text-gray-700 text-sm font-bold mb-2" for="Dob">
          Date of birth
        </label>
        <input
            class="shadow appearance-none border rounded w-full py-2 px-3 input__registerPatient text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            id="Dob"
            type="date"
            placeholder=""
            v-model="patient.dob"
        />
      </div>
    </div>
    <div class="mb-4">
      <label class="block text-gray-700 text-sm font-bold mb-2" for="address1">
        Address
      </label>
      <input
          class="shadow appearance-none border rounded w-full py-2 px-3 input__registerPatient text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
          id="address1"
          type="text"
          placeholder=""
          v-model="patient.address1"
      />
    </div>
    <div class="mb-4">
      <label class="block text-gray-700 text-sm font-bold mb-2" for="address2">
        Address 2
      </label>
      <input
          class="shadow appearance-none border rounded w-full py-2 px-3 input__registerPatient text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
          id="address2"
          type="text"
          placeholder=""
          v-model="patient.address2"
      />
    </div>
    <div class="grid grid-cols-2 gap-4">
      <div class="mb-4">
        <label class="block text-gray-700 text-sm font-bold mb-2" for="city">
          City
        </label>
        <input
            class="shadow appearance-none border rounded w-full py-2 px-3 input__registerPatient text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            id="city"
            type="text"
            placeholder=""
            v-model="patient.city"
        />
      </div>
      <div class="mb-4">
        <label class="block text-gray-700 text-sm font-bold mb-2" for="state">
          State <span class="required">*</span>
        </label>
        <el-select v-model="patient.state" class="w-full" filterable>
          <el-option value="AL" label="Alabama" />
          <el-option value="AK" label="Alaska" />
          <el-option value="AZ" label="Arizona" />
          <el-option value="AR" label="Arkansas" />
          <el-option value="CA" label="California" />
          <el-option value="CO" label="Colorado" />
          <el-option value="CT" label="Connecticut" />
          <el-option value="DE" label="Delaware" />
          <el-option value="DC" label="District Of Columbia" />
          <el-option value="FL" label="Florida" />
          <el-option value="GA" label="Georgia" />
          <el-option value="HI" label="Hawaii" />
          <el-option value="ID" label="Idaho" />
          <el-option value="IL" label="Illinois" />
          <el-option value="IN" label="Indiana" />
          <el-option value="IA" label="Iowa" />
          <el-option value="KS" label="Kansas" />
          <el-option value="KY" label="Kentucky" />
          <el-option value="LA" label="Louisiana" />
          <el-option value="ME" label="Maine" />
          <el-option value="MD" label="Maryland" />
          <el-option value="MA" label="Massachusetts" />
          <el-option value="MI" label="Michigan" />
          <el-option value="MN" label="Minnesota" />
          <el-option value="MS" label="Mississippi" />
          <el-option value="MO" label="Missouri" />
          <el-option value="MT" label="Montana" />
          <el-option value="NE" label="Nebraska" />
          <el-option value="NV" label="Nevada" />
          <el-option value="NH" label="New Hampshire" />
          <el-option value="NJ" label="New Jersey" />
          <el-option value="NM" label="New Mexico" />
          <el-option value="NY" label="New York" />
          <el-option value="NC" label="North Carolina" />
          <el-option value="ND" label="North Dakota" />
          <el-option value="OH" label="Ohio" />
          <el-option value="OK" label="Oklahoma" />
          <el-option value="OR" label="Oregon" />
          <el-option value="PA" label="Pennsylvania" />
          <el-option value="RI" label="Rhode Island" />
          <el-option value="SC" label="South Carolina" />
          <el-option value="SD" label="South Dakota" />
          <el-option value="TN" label="Tennessee" />
          <el-option value="TX" label="Texas" />
          <el-option value="UT" label="Utah" />
          <el-option value="VT" label="Vermont" />
          <el-option value="VA" label="Virginia" />
          <el-option value="WA" label="Washington" />
          <el-option value="WV" label="West Virginia" />
          <el-option value="WI" label="Wisconsin" />
          <el-option value="WY" label="Wyoming" />
        </el-select>
      </div>
      <div class="mb-4">
        <label class="block text-gray-700 text-sm font-bold mb-2" for="zip_code">
          Zip Code
        </label>
        <input
            class="shadow appearance-none border rounded w-full py-2 px-3 input__registerPatient text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            id="zip_code"
            type="text"
            placeholder=""
            v-model="patient.zip"
        />
      </div>
      <div class="mb-4">
        <label class="block text-gray-700 text-sm font-bold mb-2" for="phone">
          Phone
        </label>
        <input
            class="shadow appearance-none border rounded w-full py-2 px-3 input__registerPatient text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            id="phone"
            type="text"
            placeholder=""
            v-model="patient.phone"
        />
      </div>
      <div class="mb-4">
        <label class="block text-gray-700 text-sm font-bold mb-2" for="cell">
          Cell
        </label>
        <input
            class="shadow appearance-none border rounded w-full py-2 px-3 input__registerPatient text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            id="cell"
            type="text"
            placeholder=""
            v-model="patient.cell"
        />
      </div>
      <div class="mb-4">
        <label class="block text-gray-700 text-sm font-bold mb-2" for="sex">
          Sex
        </label>
        <div class="select__arrow-container">
          <select v-model="patient.sex" id="sex"
                  class="shadow appearance-none border rounded w-full py-2 px-3 input__registerPatient text-gray-700 leading-tight focus:outline-none focus:shadow-outline">
            <option value="m" selected>Male</option>
            <option value="f">Female</option>
          </select>
        </div>
      </div>

      <!--      <div class="mb-4" v-show="!hide_insurance">-->
      <!--        <label class="block text-gray-700 text-sm font-bold mb-2" for="member_id">-->
      <!--          Member ID / Medicare No.-->
      <!--        </label>-->
      <!--        <input-->
      <!--            class="shadow appearance-none border rounded w-full py-2 px-3 input__registerPatient text-gray-700 leading-tight focus:outline-none focus:shadow-outline"-->
      <!--            id="member_id"-->
      <!--            type="text"-->
      <!--            placeholder=""-->
      <!--            v-model="patient.member_id"-->
      <!--        />-->
      <!--      </div>-->

      <!--      <div class="mb-4" v-show="!hide_insurance">-->
      <!--        <label class="block text-gray-700 text-sm font-bold mb-2" for="payer_code">-->
      <!--          Payer Code-->
      <!--        </label>-->
      <!--        <input-->
      <!--            class="shadow appearance-none border rounded w-full py-2 px-3 input__registerPatient text-gray-700 leading-tight focus:outline-none focus:shadow-outline"-->
      <!--            id="payer_code"-->
      <!--            type="text"-->
      <!--            placeholder=""-->
      <!--            v-model="patient.payer_code"-->
      <!--        />-->
      <!--      </div>-->
    </div>

    <hr class="mb-4"/>

    <div class="mb-4">
      <label class="block text-gray-700 text-sm font-bold mb-2"> Insurance Info: </label>
    </div>

    <div class="grid grid-cols-3 gap-4">
      <div class="mb-4">
        <label class="block text-gray-700 text-sm font-bold mb-2" for="payer_id">
          Payer
        </label>

        <v-autocomplete
            v-model="patient.payer_id"
            :items="this.patients.payers"
            :item-value="item => item.id"
            :item-text="item => item.name"
            outlined
            dense

        ></v-autocomplete>
      </div>

      <div class="mb-4">
        <label class="block text-gray-700 text-sm font-bold mb-2" for="member_id">
          Member ID
        </label>
        <input
            class="shadow appearance-none border rounded w-full py-2 px-3 input__registerPatient text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            id="member_id"
            type="text"
            placeholder=""
            v-model="patient.member_id"
        />

      </div>
      <div class="mb-4">
        <label class="block text-gray-700 text-sm font-bold mb-2" for="member_group_id">
          Member Group ID / Medical Insurance Card
        </label>
        <input
            class="shadow appearance-none border rounded w-full py-2 px-3 input__registerPatient text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            id="city"
            type="text"
            placeholder=""
            v-model="patient.member_group_id"
        />

      </div>
    </div>

    <hr class="mt-4 mb-4" />

    <h2 class="text-2xl">RPM Care Plan</h2>
    <div class="mb-4">
      <label class="block text-gray-700 text-sm font-bold mb-2" for="health_condition"> Health condition <span
          class="required">*</span> </label> <input v-model="rpm_care_plan.health_condition"
                                                    class="shadow appearance-none border rounded w-full py-2 px-3 input__registerPatient text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                                    id="health_condition" type="text" placeholder=""/>
    </div>
    <div class="mb-4">
      <label class="block text-gray-700 text-sm font-bold mb-2" for="rpm_note">Start Date</label>
      <input v-model="rpm_care_plan.start_date" type="date"
             class="shadow appearance-none border rounded w-full py-2 px-3 input__registerPatient text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
             id="start_date" />
    </div>
    <div class="mb-4">
      <label class="block text-gray-700 text-sm font-bold mb-2" for="notes"> Notes <span class="required">*</span>
      </label> <textarea v-model="rpm_care_plan.notes" rows="4"
                         class="shadow appearance-none border rounded w-full py-2 px-3 input__registerPatient text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                         id="notes" placeholder=""></textarea>
    </div>
    <div class="mb-4">
      <div v-if="rpm_care_plan.icd_codes.length">
        <ul>
          <li v-for="(code, index) in rpm_care_plan.icd_codes" :key="code.id">
            {{ code.code }} - {{ code.short_desc }}
            <el-button icon="el-icon-close" circle @click="removeCode('rpm', index)"></el-button>
          </li>
        </ul>
      </div>
      <div v-else>
        No ICD Codes Selected.
      </div>

      <div>
        <el-input style="width: 50%" v-model="rpm_icd_codes_search" @input="debounceIcdCodeSearch('rpm')" placeholder="Search" prefix-icon="el-icon-search" clearable :disabled="loading" class="mb-4" />
        <div v-if="rpm_icd_codes.length > 0 && !loading">
          <ul>
            <li v-for="(code, index) in rpm_icd_codes" :key="index" v-if="!rpm_care_plan.icd_codes.find(selectedCode => selectedCode.id === code.id)">
              <a href="#" @click.prevent="addCode('rpm', index)">{{ code.code }} - {{ code.short_desc }}</a>
            </li>
          </ul>
        </div>
        <div v-else-if="rpm_icd_codes_search.length">
          <span v-if="loading" class="el-icon-loading"></span>
          <span v-else>No ICD Codes Found.</span>
        </div>
      </div>
    </div>

    <hr class="mb-4"/>

    <h2 class="text-2xl">Physician</h2>
    <div class="mb-4">
      <el-select id="select_physician" v-model="provider_id" class="w-full" clearable filterable>
        <el-option v-for="provider in providers" :value="provider.id" :key="provider.id" :label="`${provider.first_name } ${provider.last_name} ${provider.email}`" />
      </el-select>
    </div>

    <hr class="mb-4"/>

    <h2 class="text-2xl">Devices</h2>
    <div class="mb-4">
      <el-select v-model="selectedDevices" multiple placeholder="Select">
        <el-option
            v-for="device in deviceTypes"
            :key="device.name"
            :label="device.name"
            :value="device.name">
        </el-option>
      </el-select>
    </div>

    <hr class="mb-4"/>

    <h2 class="text-2xl">CCM Care Plan</h2>
    <div class="mb-4">
      <label class="block text-gray-700 text-sm font-bold mb-2" for="health_condition"> Health condition <span
          class="required">*</span> </label> <input v-model="ccm_care_plan.health_condition"
                                                    class="shadow appearance-none border rounded w-full py-2 px-3 input__registerPatient text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                                    id="health_condition" type="text" placeholder=""/>
    </div>
    <div class="mb-4">
      <label class="block text-gray-700 text-sm font-bold mb-2" for="rpm_note">Start Date</label>
      <input v-model="ccm_care_plan.start_date" type="date"
             class="shadow appearance-none border rounded w-full py-2 px-3 input__registerPatient text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
             id="start_date" />
    </div>
    <div class="mb-4">
      <label class="block text-gray-700 text-sm font-bold mb-2" for="notes"> Notes <span class="required">*</span>
      </label> <textarea v-model="ccm_care_plan.notes" rows="4"
                         class="shadow appearance-none border rounded w-full py-2 px-3 input__registerPatient text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                         id="notes" placeholder=""></textarea>
    </div>
    <div class="mb-4">
      <div v-if="ccm_care_plan.icd_codes.length">
        <ul>
          <li v-for="(code, index) in ccm_care_plan.icd_codes" :key="code.id">
            {{ code.code }} - {{ code.short_desc }}
            <el-button icon="el-icon-close" circle @click="removeCode('ccm', index)"></el-button>
          </li>
        </ul>
      </div>
      <div v-else>
        No ICD Codes Selected.
      </div>

      <div>
        <el-input style="width: 50%" v-model="ccm_icd_codes_search" @input="debounceIcdCodeSearch('ccm')" placeholder="Search" prefix-icon="el-icon-search" clearable :disabled="loading" class="mb-4" />
        <div v-if="ccm_icd_codes.length > 0 && !loading">
          <ul>
            <li v-for="(code, index) in ccm_icd_codes" :key="index" v-if="!ccm_care_plan.icd_codes.find(selectedCode => selectedCode.id === code.id)">
              <a href="#" @click.prevent="addCode('ccm', index)">{{ code.code }} - {{ code.short_desc }}</a>
            </li>
          </ul>
        </div>
        <div v-else-if="ccm_icd_codes_search.length">
          <span v-if="loading" class="el-icon-loading"></span>
          <span v-else>No ICD Codes Found.</span>
        </div>
      </div>
    </div>

    <el-button @click="createOrderForm" type="primary">Create Order Form</el-button>
  </div>
</template>

<script>


import {mapState} from "vuex";
import _ from "lodash";
import Vue from "vue";
import RpmDashboardErrors from "@/components/RpmDashboardErrors.vue";
import { inflateData } from '@/helpers/functions';

export default {
  components: {
    RpmDashboardErrors
  },
  data() {
    return {
      errors: [],
      loading: false,
      provider_id: null,
      providers: [],
      deviceTypes: [],
      selectedDevices: [],
      ehrData: {},
      clientName: '',
      patient: {},
      rpm_icd_codes: [],
      rpm_icd_codes_search: '',
      ccm_icd_codes: [],
      ccm_icd_codes_search: '',
      rpm_care_plan: {
        start_date: '',
        notes: '',
        icd_codes: [],
      },
      ccm_care_plan: {
        start_date: '',
        notes: '',
        icd_codes: [],
      },
    };
  },
  methods: {
    removeCode(type, index) {
      (type === 'rpm') ? this.rpm_care_plan.icd_codes.splice(index, 1) : this.ccm_care_plan.icd_codes.splice(index, 1);
    },
    addCode(type, index) {
      (type === 'rpm') ? this.rpm_care_plan.icd_codes.push(this.rpm_icd_codes[index]) : this.ccm_care_plan.icd_codes.push(this.ccm_icd_codes[index]);
    },
    debounceIcdCodeSearch: _.debounce(function (type) {
      this.searchIcdCodes(type);
    }, 2000),
    searchIcdCodes: function(type) {
      let icd_codes_search;
      (type === 'rpm') ? icd_codes_search = this.rpm_icd_codes_search : icd_codes_search = this.ccm_icd_codes_search;

      if (icd_codes_search.length >= 2) {
        (type === 'rpm') ? this.rpm_icd_codes = [] : this.ccm_icd_codes = [];
        this.loading = true;
        Vue.$http.get(`/api/rpm_ccm_dashboard/search_icd_codes`, {params: { search: icd_codes_search }})
            .then((res) => {
              (type === 'rpm') ? this.rpm_icd_codes = res.data.data : this.ccm_icd_codes = res.data.data;
            }).catch((error) => {
          this.$awn.alert(error.response.data.message);
        }).finally(() => {
          this.loading = false;
        });
      }
    },
    createOrderForm() {
      this.loading = true;
      this.errors = [];

      const data = {
        ehr_data: this.ehrData,
        patient: this.patient,
        rpm_care_plan: this.rpm_care_plan,
        ccm_care_plan: this.ccm_care_plan,
        selected_devices: this.selectedDevices,
        provider_id: this.provider_id,
      };

      Vue.$http.post(`/api/rpm_ccm_dashboard/create_order_form`, data)
          .then(() => this.$awn.success("Order form created successfully"))
          .catch(error => this.handleEhrApiErrors(error, this.errors, this.$refs.orderFormRef))
          .finally(() => {
        this.loading = false;
      });
    },
    loadDeviceTypes() {
      Vue.$http
          .get(`/api/rpm_ccm_dashboard/device_list`)
          .then((res) => {
            this.deviceTypes = res.data.data;
          })
          .catch(error => this.handleEhrApiErrors(error, this.errors, this.$refs.orderFormRef));
    },
    loadProviders() {
      Vue.$http
          .post(`/api/rpm_ccm_dashboard/get_providers_public`, {}, { params: { ehr_data: this.ehrData }})
          .then((res) => {
            this.providers = inflateData(res.data.data);
          })
          .catch(error => this.handleEhrApiErrors(error, this.errors, this.$refs.orderFormRef));
    },
    handleEhrApiErrors(errorResponse, vueErrors, ref) {
      let response = errorResponse.response.data;
      if (response.errors && response.errors.length > 0) {
        for (const key in response.errors) {
          if (response.errors.hasOwnProperty(key)) {
            for (const fieldKey in response.errors[key]) {
              if (response.errors[key].hasOwnProperty(fieldKey)) {
                vueErrors.push(key + ": " + response.errors[key][fieldKey]);
              }
            }
          }
        }
      } else {
        vueErrors.push(response.message);
      }
      this.$nextTick(() => {
        ref.scrollIntoView();
      });
    },
  },
  watch: {
    
  },
  computed: {
    ...mapState(["patients"]),
  },
  created() {
    try {
      this.clientName = decodeURIComponent(this.$route.params.client_name);
      this.ehrData = this.$route.params.ehr_data;
    } catch (error) {
      this.$awn.alert("Error parsing client data");
    }

    this.$store.dispatch('getPayers');
    this.loadProviders();
    this.loadDeviceTypes();
  },
};
</script>

<style scoped>
.active_tab {
  color: #5e60ce;
  border-color: #5e60ce;
}

.inactive_tab:hover {
  border-color: transparent;
}
</style>
